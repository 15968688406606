import React, { ReactNode } from "react";
// import ErrorMessage from "./ErrorPage";

// Custom hook for error handling

interface ErrorBoundaryWrapperProps {
  children: ReactNode;
}

// Error boundary wrapper using a class component
class ErrorBoundaryWrapper extends React.Component<ErrorBoundaryWrapperProps> {
  state = { error: null, errorInfo: null, hasError: false };
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    this.setState({ error: error.message || "Unexpected error" });
  }

  render() {
    // Ensure that children prop is passed along with other props
    return (
      <ErrorBoundary {...this.props} errorState={this.state}>
        {this.props.children}
      </ErrorBoundary>
    );
  }
}

// Functional component to display error
const ErrorBoundary: React.FC<{
  children: React.ReactNode;
  errorState: { error: string | null; hasError: boolean };
}> = ({ children, errorState }) => {
  const { error, hasError } = errorState;

  if (hasError) {
    return (
      <div className="justify-center text-deepDark items-center">
        {!process.env.NODE_ENV || process.env.NODE_ENV === "development" ? (
          <>
            <h2>Something went wrong</h2>
            <details style={{ whiteSpace: "pre-wrap" }}>
              {error && error.toString()}
              <br />
              {error}
            </details>
          </>
        ) : (
          <div>
            <p> Production </p>
          </div>
        )}
      </div>
    );
  }
  return <>{children}</>;
};

export default ErrorBoundaryWrapper;
