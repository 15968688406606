import React from "react";
import Cookies from "universal-cookie";

const baseUrl = process.env.PUBLIC_URL;
const cookies = new Cookies();
function Header() {
  const handleLogout = () => {
    cookies.remove("@invoice_storage_access_token", { path: "/" });
    redirect();
  };

  React.useEffect(() => {
    redirect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirect = () => {
    if (
      cookies.get("@invoice_storage_access_token") === undefined ||
      cookies.get("@invoice_storage_access_token") === null
    ) {
      window.location.href = baseUrl + "/";
      window.location.reload();
    }
  };
  return (
    <div className="bg-mainColor h-12">
      <div className="  flex py-2.5 px-3 justify-between">
        <div className="flex justify-center items-center">
          <p className=" text-lg font-bold text-white">
            Invoice Extraction System
          </p>
        </div>
        <div>
          <button
            onClick={handleLogout}
            className="bg-white text-black font-medium px-5 h-7 rounded-3xl"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default Header;
