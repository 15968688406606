import React, { useState, ChangeEvent } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import axios from 'axios';
import Config from '../../Config/Config';
import Swal from 'sweetalert2';

const cookies = new Cookies();

const getDeviceToken = cookies.get('device_token');

function Register() {
  const storedLanguage = localStorage.getItem('language') || 'en';

  React.useEffect(() => {
    i18next.init({
      lng: storedLanguage,
    });
  }, [storedLanguage]);

  const changeLanguage = (lng: string) => {
    i18next.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [termsError, setTermsError] = useState('');
  const [addLoading, setAddLoading] = useState<boolean>(false);

  const validateField = (fieldName: string, value: any) => {
    const emailPattern = /\S+@\S+\.\S+/;

    switch (fieldName) {
      case 'email':
        if (!emailPattern.test(value)) {
          setEmailError(t('emailFormatError'));
          return false;
        } else {
          setEmailError('');
          return true;
        }
      case 'password':
        if (value.length <= 7) {
          setPasswordError(t('passwordLengthError'));
          return false;
        } else {
          setPasswordError('');
          return true;
        }
      case 'confirmPassword':
        if (value !== password) {
          setConfirmPasswordError(t('passwordMismatchError'));
          return false;
        } else {
          setConfirmPasswordError('');
          return true;
        }
      case 'termsAccepted':
        if (!value) {
          setTermsError(t('termsAcceptanceError'));
          return false;
        } else {
          setTermsError('');
          return true;
        }
      default:
        return true;
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    validateField('email', e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    validateField('password', e.target.value);
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    validateField('confirmPassword', e.target.value);
  };

  const handleTermsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(e.target.checked);
    validateField('termsAccepted', e.target.checked);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setAddLoading(true);

    const emailPattern = /\S+@\S+\.\S+/;
    let isValid = true;

    if (!emailPattern.test(email)) {
      setEmailError(t('emailFormatError'));
      isValid = false;
    }

    if (password.length <= 7) {
      setPasswordError(t('passwordLengthError'));
      isValid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(t('passwordMismatchError'));
      isValid = false;
    }

    if (!termsAccepted) {
      setTermsError(t('termsAcceptanceError'));
      isValid = false;
    }

    if (!isValid) {
      setAddLoading(false);
      return;
    }

    const device_token = getDeviceToken || '';

    axios({
      method: 'POST',
      url: Config.API_URL._registerAPI,
      data: {
        email: email,
        password: password,
        device_token: device_token,
      },
    })
      .then((response) => {
        Swal.fire({
          title: t('pleaseWaitForActivation'),
          icon: 'success',
          confirmButtonColor: '#5b5b5b',
          cancelButtonColor: '#d33',
          reverseButtons: true,
        });
      })
      .catch((error) => {
        setAddLoading(false);
        setEmailError(
          error.response?.data?.message ||
            error.response?.data?.error_description ||
            error.response?.data?.error ||
            t('registrationError')
        );
      });
  };

  /*const accessToken = response.data.access_token;
    .then((response) => {
      if (response.status >= 200 && response.status <= 299) {
          cookies.set("@invoice_storage_access_token", accessToken, {
            path: "/",
            maxAge: 36000,
          });
          cookies.set("@invoice_storage_username", email, {
            path: "/",
            maxAge: 36000,
          });
          window.location.href = baseUrl + "/";
          window.location.reload();
        }
      })
  };*/

  return (
    <div className="dark:bg-deepDark relative dark:text-white text-black overflow-hidden background-image">
      <div className="absolute text-white right-5 top-5 text-xl flex gap-2 h-8 min-w-[150px] items-center justify-center">
        <button
          className="pr-1 hover:scale-110 hover:bg-red-700 hover:p-1.5 rounded-md hover:text-2xl"
          onClick={() => changeLanguage('en')}
        >
          EN
        </button>
        {'|'}
        <button
          className="hover:scale-110 hover:bg-red-700 hover:p-1.5 rounded-md hover:text-2xl"
          onClick={() => changeLanguage('pl')}
        >
          PL
        </button>
      </div>
      <div className="container lg:px-12 px-6 mx-auto">
        <div className="flex flex-col text-center md:text-left md:flex-row h-screen justify-evenly md:items-center">
          <div className="w-full md:w-1/2 lg:w-1/4 mx-auto md:mx-0">
            <div className="dark:bg-gray-800 border-1 bg-white p-10 flex flex-col w-full drop-shadow-2xl rounded-md">
              <div className="mb-2">
                <img
                  src="/DFlogo.svg"
                  alt="DFLogo"
                  className="mx-auto mb-6 w-[200px]"
                />
                <h2 className="text-2xl font-bold text-center">
                  {t('register')}
                </h2>
                <p className="text-sm opacity-60 py-4 text-center">
                  {t('registerDescription')}
                </p>
              </div>
              <form onSubmit={handleSubmit} className="w-full">
                <div className="py-1 h-[115px]">
                  <label className="m-1">{t('usernameLabel')}</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="jan.kowalski@example.pl"
                    required
                    value={email}
                    onChange={handleEmailChange}
                    className="bg-gray-100 w-full py-3 px-4 rounded my-2"
                    autoComplete="new-email"
                  />
                  {emailError && (
                    <p className="text-red-500 text-left">{emailError}</p>
                  )}
                </div>
                <div className="py-1 h-[130px]">
                  <label className="m-1">{t('passwordLabel')}</label>
                  <input
                    type="password"
                    name="password"
                    placeholder={t('passwordLabel')}
                    required
                    value={password}
                    onChange={handlePasswordChange}
                    className="bg-gray-100 w-full py-3 px-4 rounded my-2"
                    autoComplete="new-password"
                  />
                  {passwordError && (
                    <p className="text-red-500 text-left">{passwordError}</p>
                  )}
                </div>
                <div className="py-1 h-[130px]">
                  <label className="m-1">{t('confirmPasswordLabel')}</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder={t('confirmPasswordLabel')}
                    required
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    className="bg-gray-100 w-full py-3 px-4 rounded my-2"
                    autoComplete="new-password"
                  />
                  {confirmPasswordError && (
                    <p className="text-red-500 text-left">
                      {confirmPasswordError}
                    </p>
                  )}
                </div>
                <div className="py-1 flex items-center justify-center">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={termsAccepted}
                    onChange={handleTermsChange}
                    className="mr-2"
                  />
                  <span className=" text-sm">
                    {t('accept')}{' '}
                    <a
                      className="border-b border-red-500 text-red-800"
                      target="_blank"
                      href="https://docs.google.com/document/d/1Gvs2f4uWKtyYoqi1m7D_tkOWAVYLzH24fRZdXeMFxL0/edit#heading=h.zaxs4i52r2pw"
                    >
                      {t('termsLabel')}
                    </a>{' '}
                    {t('and')}{' '}
                    <a
                      className="border-b border-red-500 text-red-800"
                      target="_blank"
                      href="https://docs.google.com/document/d/1AcjjZZQyEd1K-Pl5edkVIzG_gzExlmmJFMUXms8H79A/edit"
                    >
                      {t('policy')}
                    </a>
                    .
                  </span>
                </div>
                {termsError && (
                  <p className="text-red-500 text-left">{termsError}</p>
                )}
                <div id="button" className="flex flex-col w-full my-4">
                  <button
                    type="submit"
                    className="red-df w-full py-2 uppercase justify-center text-white font-bold px-6 rounded inline-flex items-center group"
                  >
                    <span className="mr-2">
                      {addLoading ? t('loading') : t('registerButton')}
                    </span>
                    <BsArrowRight className="transition ease-in-out delay-100 text-xl hidden group-hover:block" />
                  </button>
                  <a
                    href="/"
                    className="w-20 text-center mt-8 mx-auto text-red-600 border-b border-red-600 hover:scale-110"
                  >
                    {t('login')}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
