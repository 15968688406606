import React from "react";
import { PublicPath, PrivateRoutes, ClientRoutes } from "./Router";
import Config from "../Config/Config";
import { AuthContext } from "../Screen/Context/AuthContext";

function demoAsyncCall(): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), 2500));
}

function RoutesLink() {
  const [loadinge, setLoadinge] = React.useState(true);
  const { user } = React.useContext(AuthContext);

  React.useEffect(() => {
    demoAsyncCall().then(() => setLoadinge(false));
  }, [user]);

  if (loadinge) {
    // Return a loader element while app is loading
    return (
      <div className="flex justify-center items-center h-screen">
        <img src="/loadingRed.svg" alt="loader" className="w-1/3" />
      </div>
    );
  }

  // Once loading is complete, render the routes based on token validity
  if (Config.isValidToken && user) {
    return user.admin ? <PrivateRoutes /> : <ClientRoutes />;
  }

  return <PublicPath />;
}

export default RoutesLink;
