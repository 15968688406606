import Cookies from 'universal-cookie';

const cookie = new Cookies();

// Get the token value of the cookie with the specified name
const authToken = cookie.get('@invoice_storage_access_token');

// Check if env is production or development
const api = process.env.REACT_APP_DEVELOPMENT;
const Config = {
  // List of all the API's
  API_URL: {
    _loginAPI: `${api}users/oauth/token/login/`,
    _logoutAPI: ` ${api}oauth/revoke_token/`,
    _registerAPI: `${api}users/oauth/register/`,
    _invoiceAPI: `${api}invoice/invoice-data/`,
    _invoice_api: `${api}invoice/invoice-list/`,
    _get_profile: `${api}users/get-profile/`,
    _invoice_extraction: `${api}invoice/invoice-extraction/`,
    _generate_csv: `${api}invoice/generate-csv/`,
    _save_invoice: `${api}invoice/save-invoice/`,
    _user_invoices: `${api}invoice/user-invoices`,
    _get_invoice: `${api}invoice/get-invoice`,
    _get_image: `${api}invoice/get-image`,
    _update_plates: `${api}users/update-plates/`,
  },

  // Valid token for dashboard
  isValidToken: authToken,

  // Headers with method and token for api
  headerAuth: {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // "Accept-Language": window.localStorage.i18nextLng,
      Authorization: 'Bearer ' + authToken,
    },
  },
};
export default Config;
