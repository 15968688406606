import { lazy } from 'react';
import Register from '../Screen/Login/Register';

/* 
==========================================
 Public Router Path
==========================================
*/

const Login = lazy(() => import('../Screen/Login'));

/*
==========================================
 Private Router Path
==========================================
*/

const Dashboard = lazy(() => import('../Screen/Invoice/InvoiceList'));
const InvoiceDetails = lazy(
  () => import('../Screen/Invoice/InvoiceList/Details')
);
const InvoiceCreate = lazy(() => import('../Screen/Invoice'));
const MainPage = lazy(() => import('../Screen/Client/MainPage'));
const AddInvoice = lazy(() => import('../Screen/Client/AddInvoice/AddInvoice'));

const routesPath = {
  Public: [
    {
      path: '/',
      component: <Login />,
      exact: true,
    },
    {
      path: '/register',
      component: <Register />,
      exact: true,
    },
  ],
  Private: [
    {
      path: '/',
      component: <Dashboard />,
      exact: true,
    },
    {
      path: '/invoice/create/',
      component: <InvoiceCreate />,
      exact: true,
    },
    {
      path: '/invoice/:id/details/',
      component: <InvoiceDetails />,
      exact: true,
    },
  ],

  Client: [
    {
      path: '/',
      component: <MainPage />,
      exact: true,
    },
    {
      path: '/add-invoice/:id?/:user?',
      component: <AddInvoice />,
      exact: true,
    },
  ],
};

export default routesPath;
