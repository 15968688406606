import React from "react";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function HeaderClient() {
  const cookies = new Cookies();
  const baseUrl = process.env.PUBLIC_URL;
  const { i18n } = useTranslation();
  const storedLanguage = localStorage.getItem("language") || "en"; // Default to English if no language is stored

  React.useEffect(() => {
    i18next.init({
      lng: storedLanguage,
    });
  }, [storedLanguage]);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  const handleLogout = () => {
    const redirect = () => {
      cookies.remove("@invoice_storage_access_token", { path: "/" });
      if (
        cookies.get("@invoice_storage_access_token") === undefined ||
        cookies.get("@invoice_storage_access_token") === null
      ) {
        window.location.href = baseUrl + "/";
        window.location.reload();
      }
    };
    redirect();
  };
  return (
    <div className="h-20 border flex justify-center items-center">
      <img className="w-48 p-4 h-full" src="/DFlogo.svg" alt="DFlogo" />
      <div className="ml-auto mr-10 text-xl flex gap-2">
        <button
          className="pr-1 hover:scale-110 hover:bg-gray-100 hover:p-1 rounded-md hover:text-2xl"
          onClick={() => changeLanguage("en")}
        >
          EN
        </button>
        {"|"}
        <button
          className="pl-1 hover:scale-110 hover:bg-gray-100 hover:p-1 rounded-md hover:text-2xl"
          onClick={() => changeLanguage("pl")}
        >
          {" "}
          PL
        </button>
      </div>
      <button
        className=" mr-8 text-xl rounded-md h-12 px-4 hover:bg-gray-200 hover:scale-110 hover:border"
        onClick={handleLogout}
      >
        <img src="/logout.svg" alt="logout" className="w-full h-8" />
      </button>
    </div>
  );
}

export default HeaderClient;
