import axios from "axios";
import React, { createContext, useState, useEffect, ReactNode } from "react";
import Config from "../../Config/Config";
import Cookies from "universal-cookie";
const cookies = new Cookies();
// Define the type for the context

interface AuthContextType {
  user: any; // Assuming user data will be stored here
  loading: boolean;
  error?: string; // Optional error message
}

// Create the context with an initial empty object of type AuthContextType
export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

// Define the props interface for AuthProvider
interface AuthProviderProps {
  children: ReactNode; // ReactNode type allows any React child elements
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, setState] = useState<AuthContextType>({
    user: null,
    loading: true,
    error: undefined,
  });

  useEffect(() => {
    const username = cookies.get("@invoice_storage_username");
    const token = cookies.get("@invoice_storage_access_token");

    if (username && token) {
      axios({
        method: "POST",
        url: Config.API_URL._get_profile,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        data: `username=${username}`,
      })
        .then((response) => {
          setState({
            user: response.data,
            loading: false,
            error: undefined,
          });
        })
        .catch((error) => {
          setState({
            user: null,
            loading: false,
            error: `Error fetching user data: ${error.message}`,
          });
        });
    } else {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: "User not logged in",
      }));
    }
  }, []);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
