import React, { Suspense, ReactNode } from "react";
import { Route, Navigate, BrowserRouter, Routes } from "react-router-dom";
import Loading from "../Screen/Loading";
import Config from "../Config/Config";
import routesPath from "./RouterPath";
import ErrorBoundary from "../Screen/ErrorBoundary";
import Header from "../Screen/Components/Header";
import HeaderClient from "../Screen/Client/HeaderClient";

interface RequireAuthProps {
  children: React.ReactNode;
  redirectTo: string;
}

// Checking if the token is valid to authenticate the user
const isAuthenticated = Config?.isValidToken;

// RequireAuth component to handle authenticated routing
function RequireAuth({ children, redirectTo }: RequireAuthProps) {
  // If authenticated, render children; otherwise, redirect to specified route
  return isAuthenticated ? <>{children}</> : <Navigate to={redirectTo} />;
}

export const PrivateRoutes = () => (
  <BrowserRouter>
    <Header />
    <Suspense fallback={<Loading />}>
      <Routes>
        {routesPath?.Private?.map((c, i) => (
          <Route
            path={c.path}
            key={i + 1}
            element={
              <RequireAuth redirectTo={"/"}>
                <ErrorBoundary>{c.component}</ErrorBoundary>
              </RequireAuth>
            }
          ></Route>
        ))}
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export const ClientRoutes = () => (
  <BrowserRouter>
    <HeaderClient />
    <Suspense fallback={<Loading />}>
      <Routes>
        {routesPath?.Client?.map((c, i) => (
          <Route
            path={c.path}
            key={i + 1}
            element={
              <RequireAuth redirectTo={"/"}>
                <ErrorBoundary>{c.component}</ErrorBoundary>
              </RequireAuth>
            }
          ></Route>
        ))}
      </Routes>
    </Suspense>
  </BrowserRouter>
);
// Defining the interface for the NotRequireAuth component props
interface NotRequireAuthProps {
  children: ReactNode;
  redirectTo: string;
}
//UserRequestPrivate

// NotRequireAuth component to handle unauthenticated routing
function NotRequireAuth({ children, redirectTo }: NotRequireAuthProps) {
  if (isAuthenticated) {
    return <Navigate to={redirectTo} />;
  }

  // Validating and rendering the children element
  if (React.isValidElement(children)) {
    return <>{children}</>;
  }

  // Default fallback when children is not a valid element
  return <div></div>;
}

// Component for rendering public (unauthenticated) routes
export const PublicPath = () => (
  <div className="r">
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          {routesPath?.Public.map((c, i) => (
            <Route
              path={c.path}
              key={i + 1}
              element={
                <NotRequireAuth redirectTo={"/"}>
                  <ErrorBoundary>{c.component}</ErrorBoundary>
                </NotRequireAuth>
              }
            ></Route>
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  </div>
);
