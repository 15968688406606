import React from "react";
import loadIcon from "../../Asset/Image/loader.svg";
function Loading() {
  return (
    <div className="flex min-h-screen bg-mainGray items-center justify-center relative    ">
      <div className=" ">
        <img alt="loading" src={loadIcon} className="w-1/2" />
      </div>
    </div>
  );
}

export default Loading;
